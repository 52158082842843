import React from 'react';
import { Global } from '@emotion/core';
import styled from '@emotion/styled';
import { MDXProvider } from '@mdx-js/react';
import PropTypes from 'prop-types';
import {
    A,
    GoogleTagManager,
    Footer,
    Header,
    SEO,
    YouTubeEmbed,
    // text elements
    BlockQuote,
    StyledDate,
    Tag,
    H1,
    H2,
    H3,
    H4,
    H5,
    P,
    // layouts
    HR,
    layoutContainer,
    spacings,
    // styles
    GlobalStyles,
} from '../../../../packages/shared';

const headline = styled(H2)`
  margin-bottom: ${spacings.header};
`;

const sectionHeader = styled(H3)`
  margin-bottom: ${spacings.header};
`;

const summaryLines = styled(H5)`
  margin-bottom: ${spacings.unit};
`;

const components = {
    YouTubeEmbed,
    h1: H1,
    h2: headline,
    h3: sectionHeader,
    h4: H4,
    h5: summaryLines,
    p: P,
    a: A,
    blockquote: BlockQuote,
    hr: HR,
};

const RecipeContainer = styled('div')`
  ${layoutContainer}
`;

const RecipeSection = styled('div')`
  margin-bottom: ${spacings.section};
`;

const Recipe = ({ children, pageContext }) => {
    const { frontmatter } = pageContext;
    const { author, date, description, slug, tags, title } = frontmatter;

    const formattedDate = new Date(date).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    });

    const ISODate = new Date(date).toISOString().substring(0, 10);

    return (
        <MDXProvider components={components}>
            <GoogleTagManager />
            <Global styles={GlobalStyles} />
            <SEO
                title={title}
                description={description}
                author={author}
                slug={slug}
                siteData={{ siteMetadata: { siteUrl: 'https://recipes.ericaashley.com' }}}
            />
            <Header />
            <RecipeContainer itemscope itemtype="https://schema.org/Recipe">
                <main>
                    <RecipeSection>
                        <H1 itemprop="name">{title}</H1>
                        <H5>
                            <Tag>{tags.join(', ')}</Tag>
                        </H5>
                        <StyledDate>
                            <meta itemProp="dateModified" content={ISODate} />
                            {formattedDate}
                        </StyledDate>
                    </RecipeSection>

                    <RecipeSection>{children}</RecipeSection>
                </main>
            </RecipeContainer>
            <Footer />
        </MDXProvider>
    );
};

Recipe.propTypes = {
    children: PropTypes.any,
    pageContext: PropTypes.object,
};

export default Recipe;
