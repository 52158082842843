import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/packages/recipes/src/layouts/recipe.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h5>{`Prep time: 30 min. `}<meta itemProp="prepTime" content="PT30M" /></h5>
    <h5>{`Cook time: 90 min. `}<meta itemProp="cookTime" content="PT90M" /></h5>
    <h5>{`Servings: `}<span itemProp="recipeYield">{`6-8`}</span></h5>
    <hr></hr>
    <h2>{`Ingredients`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Flour, 3 cups</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Baking powder, 1 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Salt, 1 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Sugar, 1 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">
  Cheese, 1 cup shredded (Cabot New York Extra Sharp Cheddar)
        </span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Jalapeno peppers, 1/4 cup chopped</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">
  Beer, 1 can (12 oz. PBR, otherwise a lager or IPA works well)
        </span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Butter, 4 tbs.</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Equipment`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="tool">Loaf pan</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Large mixing bowl</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Sifter</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Measuring cups + spoons</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Grater</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Small bowl</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Prep`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="step">Melt butter</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">
  Butter the loaf pan with a little bit of the melted butter
        </span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Grate cheese</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Chop jalapeno peppers</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Instructions`}</h2>
    <span itemProp="recipeInstructions">
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Sift dry ingredients into large bowl: flour, baking powder salt, and sugar.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Mix in beer.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Mix in cheese and jalapeno peppers.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Pour batter into buttered loaf pan.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Pour remaining melted butter over the top of the batter.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Bake at 375F for 45-60 minutes, until the loaf looks golden with some crisp edges. A knife or toothpick should come out clean.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Let cool 10-15 minutes before serving.`}</p>
        </li>
      </ol>
    </span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      